






































































































































































































































































import api from "@/api"; //ABP API接口
import {Vue, Component, Ref} from "vue-property-decorator";
import ImportExcel from "@/components/ImportExcel/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

import {
  DataDictionaryDto,
  DataDictionaryDtoPagedResultDto, UserDto
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import moment from "moment";
import ExportButton from "@/components/ExportButton/index.vue";
import {regionData, CodeToText} from "element-china-area-data";
import ElOptionSpan from "@/components/AbSelect/elOptionSpan.vue";

Vue.use(regionData, CodeToText);
@Component({
  name: "ServiceList",
  components: {
    ElOptionSpan,
    PagedTableView,
    AbSelect,
    ImportExcel,
    AgileAuditTag,
    ExportButton
  }
})
export default class ServiceList extends Vue {
  @Ref() readonly pagedTableView!: any;
  selectedOptions: any[] = ["", "", ""];
  options: any = regionData;
  provinceName = "";
  cityName = "";
  areaName = "";

  queryForm: any = {
    provinceId: undefined,
    cityId: undefined,
    areaId: undefined,
    title: undefined,
    serviceObjectTypeId: undefined,
    status: undefined,
    startDate: undefined,
    endDate: undefined,
    host: undefined,
    leaderId: undefined,
    serviceObjectIds: undefined,
    isFeedback: undefined,
  };
  exportFieldList = [
    "服务标题",
    "服务类型",
    "主办方",
    "服务开始时间",
    "服务结束时间",
    "地点",
    "服务积分",
    "状态",
    "领队",
    "单次有效服务时长（小时）",
    "服务机构",
    "服务地点",
    "服务时间",
    "报名截止时间",
    "志愿者星级",
    "志愿者积分",
    "限制报名人数",
    "是否人工审核",
    "服务详情",
    "服务ID"
  ];

  statusList: any = [];
  serviceTypeList: any = [];

  created() {
    this.fetchDataDictionary();
    this.getLeaderList();
    this.getServiceObjectList();
  }

  jumpSignUpDetail(row: any) {
    this.$router.push({
      name: "serviceApply-SignUpDetail",
      params: {id: row.id!.toString()},
    });
  }

  jumpServiceRecordDetail(row: any) {
    this.$router.push({
      name: "serviceRecordHasServiceId",
      params: {serviceId: row.id!.toString()},
    });
  }

  async fetchDataDictionary() {
    //服务类型
    await api.dataDictionary.getDataDictionaryListByKey({
      key: "ServiceType"
    }).then((res) => {
      this.serviceTypeList = res.items!;
    });
    //状态
    await api.enumService.getValues({
      typeName: "ServiceStatus"
    })
      .then((res) => {
        this.statusList = res!;
      });
  }

  // 获取表数据
  async fetchData(params: any) {
    if (this.$route.query.serviceObjectIds){
      params.serviceObjectIds=this.$route.query.serviceObjectIds;
    }
    return await api.serviceMaintainService.getAll(params);
  }

  leaderList: any = [];
  serviceObjectList: any = [];

  async getLeaderList() {
    await api.volunteer.getApprovedVolunteerList().then((res: UserDto[]) => {
      this.leaderList = [...res];
    })
  }

  async getServiceObjectList() {
    await api.serviceObject.getAll({maxResultCount: 65535}).then((res) => {
      this.serviceObjectList = res.items!;
    });
  }

  //   省市区
  handleChange(value: any, label: any) {
    this.queryForm!.provinceId = value[0];
    this.queryForm!.cityId = value[1];
    this.queryForm!.areaId = value[2];
  }

  getProvince(id: any) {
    api.province.get({id: id}).then((res) => {
      this.provinceName = res.provinceName!;
    });
    return this.provinceName;
  }

  getCity(id: any) {
    api.city.get({id: id}).then((res) => {
      this.cityName = res.cityName!;
    });
    return this.cityName;
  }

  getArea(id: any) {
    api.area.get({id: id}).then((res) => {
      this.areaName = res.areaName!;
    });
    return this.areaName;
  }

  //发布
  handlePublish(item: any) {
    this.$confirm("确定发布吗?", "提示").then(() => {
      api.serviceMaintainService.publish({id: item.id}).then(res => {
        this.$message.success("发布成功");
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  // 取消发布
  handleCancelPublish(item: any) {
    let confirmMessage = "确定取消发布吗?";
    if ((item.signUpCount ?? 0) > 0) {
      confirmMessage = "确定取消发布吗?当前服务已有用户报名，取消服务会造成报名用户无法继续服务，请谨慎操作";
    }
    this.$confirm(confirmMessage, "提示").then(() => {
      api.serviceMaintainService.cancelPublish({id: item.id}).then(res => {
        this.$message.success("取消发布成功");
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  // 跳转编辑页
  handleEdit(item: any) {
    this.$router.push({
      name: "serviceMaintain-edit",
      params: {id: item.id!.toString(), isHelpApplies: "false"}
    });
  }

  handleObjectFeedbackList(item: any) {
    this.$router.push({
      name: "serviceObjectFeedbackList",
      params: {serviceId: item.id!.toString()}
    });
  }

  // 跳转服务日记列表
  handleServiceFeelingsList(item: any) {
    this.$router.push({
      name: "serviceFeelingsList",
      params: {serviceId: item.id!.toString()}
    })
  }

  // 新建
  handleCreate() {
    this.$router.push({
      name: "serviceMaintain-create"
    });
  }

  //删除
  handleDelete(item: any) {
    this.$confirm("确定删除吗?", "提示").then(() => {
      api.serviceMaintainService.delete({id: item.id}).then(res => {
        this.$message.success("删除成功");
        (this as any).$bus.$emit("refresh-data");
      });
    });
  }

  // 查看详情
  jumpDetail(index: number, row: any) {
    this.editId = row.id!;
    this.$router.push({
      name: "serviceMaintain-detail",
      params: {id: row.id!.toString()}
    });
  }
}
